<template>
    <div>
        <Slider
            :value="value"
            @change='updateValue'
            :max='23.99'
            :step='0.25'
            :format='format'
        />
    </div>
</template>

<script>
import '@vueform/slider/themes/default.css';

export default {
    props: ['value'],
    methods: {
        updateValue: function(value) {
            this.$emit('input', value);
        },
        format: function (value) {
            return this.formatHours(value);
        }
    }
}
</script>
